<template>
  <div>
    <h1 class="title is-3 noselect has-text-left">CDN Image Report</h1>
    <h2 class="subtitle has-text-grey is-6">Report Invaild CDN Images</h2>
    <b-field label="Reporting User*">
      <div v-if="this.$store.getters.user" style="align-items: left; justify-content: left; display: flex;">
        <img :src="'https://cdn.discordapp.com/avatars/' + this.$store.getters.user.id + '/' + this.$store.getters.user.avatar + '.webp?size=128'" onerror="this.src='https://cdn.discordapp.com/embed/avatars/1.png?size=128';" class="icon-img has-shadow" style="max-height: 36px; margin-right: 5px; border-radius: 100px; border-color: var(--main-color-bg-3); border-width: 1px; border-radius: 100px; border-style: solid; background-color: var(--main-color-bg) !important;">
        <p style="line-height:35px">{{ this.$store.getters.user.username }}#{{ this.$store.getters.user.discriminator }}</p>
      </div>
      <b-button tag="router-link" :to="{path: '/login'}" v-else type="is-primary">Login</b-button>
    </b-field>
    <b-field label="Broken Image*">
      <v-select appendToBody :selectOnTab="true" placeholder="Search a Image ID!" :calculatePosition="vselectfixer" :disabled="false" autocomplete @search="onSearch" :filterable="false" :searchable="true" required :clearable="true" :reduce="item => item.id" :options="imageList" v-model="brokenImage" :getOptionLabel="option => option.type" label="CDN Image">
        <template slot="option" slot-scope="option">
          <div class="columns level" style="padding-right: 0px !important;">
            <div class="column is-half" style="text-overflow: ellipsis;white-space: pre-line;overflow: hidden;color: white;">
              <b-tag class="Nunito" v-if="option.nsfw" rounded type="is-danger">NSFW</b-tag>
              {{ option.id }}
            </div>
            <div class="column is-half" :style="{'-webkit-filter: blur(25px);': option.nsfw}">
              <!-- <img :src="option.url+'dwadaw'" width="200" /> -->
              <b-image :src="option.url" width="200" ratio="5by4" src-fallback="https://cdn.gracebot.net/grace/GraceLogoBG-1.png">
              </b-image>
            </div>
          </div>
        </template>
        <template slot="selected-option" slot-scope="option">
          <div class="selected d-center-s">
            <b-tag class="Nunito" v-if="option.nsfw" rounded type="is-danger">NSFW</b-tag> {{ option.id }}
          </div>
        </template>
      </v-select>
    </b-field>
    <b-field label="Reason">
      <b-input :icon-right="reason ? 'close-circle' : null" icon-right-clickable @icon-right-click="reason = null;" maxlength="500" rows="5" placeholder="Enter a short message on the reason why your reporting this image." v-model="reason" :has-counter="true" custom-class="input-default" type="textarea"></b-input>
    </b-field>
  </div>
</template>

<script>
  import 'vue-select/dist/vue-select.css'
  import vSelect from 'vue-select'
  import { createPopper } from '@popperjs/core'
  import { debounce } from 'underscore'

  export default {
    name: 'cdnimage',
    components: {
      vSelect
    },
    computed: {
    },
    data () {
      return {
        SelectPlacement: 'bottom',
        brokenImage: null,
        reason: null,
        imageList: [],
        searchStringPrev: '',
        requiredValues: ['brokenImage'],
        optionalValues: ['reason']
      }
    },
    created () {
      for (let i = 0; i < [...this.requiredValues, ...this.optionalValues].length; i++) {
        const element = [...this.requiredValues, ...this.optionalValues][i]
        console.log(`Now Watching ${element} in cdnImage`)
        this.$parent.values[element] = (this[element] === '' || this[element] === '' ? null : this[element]) || null
        this.$watch(element, function () {
          this.$parent.values[element] = (this[element] === '' || this[element] === '' ? null : this[element]) || null
          this.updateValues()
          return true
        })
      }

      if (this.$route.query.reportId) {
        this.$store.dispatch('getApi', { path: 'plugin/cdn', params: { id: this.$route.query.reportId.trim() + '*', status: '*' } }).then(async (result) => {
          this.imageList = result.data.results
          this.brokenImage = this.$route.query.reportId
        }).catch(async () => {
          this.imageList = []
        })
      }
    },
    watch: {
      // 'brokenImage' () {
      //   this.$parent.componentData = {
      //     required: ['brokenImage'],
      //     values: { brokenImage: this.brokenImage }
      //   }
      // }
    },
    methods: {
      updateValues () {
        let allowSubmit = true
        for (let i = 0; i < this.requiredValues.length; i++) {
          const element = this.requiredValues[i]
          if (this[element] === undefined || this[element] === null) {
            allowSubmit = false
            break
          }
        }
        this.$parent.allowSubmit = allowSubmit
        return allowSubmit
      },
      onSearch (search, loading) {
        if (!search || search.length <= 0 || this.searchStringPrev === search.trim()) return
        this.searchStringPrev = search.trim()
        this.search(loading, search, this)
        // console.log(search, loading)
      },
      search: debounce((loading, search, vm) => {
        if (loading) loading(true)
        vm.$store.dispatch('getApi', { path: 'plugin/cdn', params: { id: search.trim() + '*', status: '*' } }).then(async (result) => {
          vm.imageList = result.data.results
          if (loading) loading(false)
        }).catch(async () => {
          vm.imageList = []
          if (loading) loading(false)
        })
      }, 350),
      vselectfixer (dropdownList, component, { width }) {
        dropdownList.style.width = width
        const popper = createPopper(component.$refs.toggle, dropdownList, {
          placement: this.SelectPlacement,
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, -1]
              }
            },
            {
              name: 'toggleClass',
              enabled: true,
              phase: 'write',
              fn ({ state }) {
                component.$el.classList.toggle('drop-up', state.SelectPlacement === 'top')
              }
            }
          ]
        })
        return () => popper.destroy()
      }
    }
  }
</script>

<style>
  .vs--disabled .vs__dropdown-toggle, .vs--disabled .vs__clear, .vs--disabled .vs__search, .vs--disabled .vs__selected, .vs--disabled .vs__open-indicator {
    background: var(--main-color-bg-2);
    cursor: not-allowed;
    color: #7a7a7a !important;
  }
  .vs__dropdown-toggle {
    background: var(--main-color-bg-2);
    color: white !important;
    border-color: var(--main-color-bg-3) !important;
    min-height: 2.25em !important;
    height: 100% !important;
    border: 1px solid transparent;
    border-radius: 4px;
    box-shadow: 0 0.1rem 1.1rem rgba(0,0,0,.3) !important;
  }
  .vs__dropdown-menu {
    background: var(--main-color-bg-1);
    color: white;
    border-color: var(--main-color-bg-3) !important;
    border-top-style: unset !important;
    border-radius: 4px 4px 4px 4px !important;
  }
  .vs__open-indicator {
    fill: #6045aa !important;
  }
  .vs__open-indicator:hover {
    fill: #7957d5 !important;
  }
  .vs__clear {
    fill: darkred !important;
  }
  .vs__clear:hover {
    fill: red !important;
  }
  .vs--single .vs__selected {
    color: white;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
    overflow: hidden !important;
  }
  .vs__dropdown-option--selected {
    background-color: var(--main-color-bg-2);
  }
  .vs__dropdown-option--highlight {
    background: var(--main-color-bg-3) !important;
  }
  .vs__actions {
    cursor: pointer;
  }
  .vs__search, .vs__search:focus {
    color: white;
  }
  .vs__selected {
    background-color: var(--main-color-bg-1);
    color: white;
  }
  .vs__deselect {
    fill: darkred;
  }
  .vs__deselect:hover {
    fill: red;
  }
</style>
