<template>
  <div>
    <section class="section" style="padding-top: 0px;">
      <div class="container Nunito" style="padding: 0;">
        <section class="section headerSection" style="padding-top: 20px;">
          <div class="container has-text-centered">
            <h1 class="title is-2 noselect">Report</h1>
            <h1 style="margin-bottom:0px" class="subtitle has-text-white is-5 noselect">Here is where you can report issue about grace.</h1>
            <h1 style="margin-bottom:0px" class="subtitle has-text-white is-6 noselect">Different options will show base of request.</h1>
          </div>
        </section>
        <section class="section" style="padding: 0;">
          <div class="columns">
            <div class="column is-centered" style="padding: 0px;">
              <div class="card options has-shadow has-shadow has-text-white">
                <h1 class="title" v-if="!this.loaded">Loading...</h1>
                <h1 class="title" v-else>Report</h1>
                <h2 class="subtitle has-text-grey is-6">
                  Help improve the proformance and saftey of Grace Bot by creating a report! Click the a type of report you wish to make to start!
                  <br><b>Creating false reports can lead to punishments made on your account with grace.</b>
                  <br><code>*</code> = Required
                </h2>
                <!-- <b-field class="hovermessagefeild" label="Command Search">
                  <b-input custom-class="input-default" maxlength="40" has-counter v-model="cmdSearch" placeholder="Enter command" :icon-right="cmdSearch ? 'close-circle' : null" icon-right-clickable @icon-right-click="cmdSearch = null"></b-input>
                </b-field> -->
                <b-menu>
                  <b-menu-list label="Report Type" :class="{'is-disabled': this.addingReport || this.bannedFromReports || !this.loaded}" :disabled="addingReport || bannedFromReports || !loaded">
                    <b-menu-item :disabled="addingReport || bannedFromReports || !loaded" v-for="(category) in categorys" :key="category.type" :value="category.name" @click="selectedCategory = category.type;" :active="selectedCategory === category.type">
                      <template slot="label">
                        <span>
                          {{category.name}}
                        </span>
                      </template>
                    </b-menu-item>
                  </b-menu-list>
                </b-menu>
                <h2 v-if="this.$store.getters.user" class="subtitle has-text-grey is-6" style="margin-top: 10px;">
                  You are logged in as <b>{{this.$store.getters.user.tag}}</b>
                </h2>
              </div>
              <div class="card options has-shadow has-shadow has-text-white" v-if="(this.userReports || []).length > 0 && this.loaded">
                <h1 class="title">Past Reports</h1>
                <h2 class="subtitle has-text-grey is-6">A list of your quick info on your past reports in a nice list.</h2>
                <b-table :data="this.userReports || []" :mobile-cards="false" :paginated="(this.userReports || []).length > 3" :current-page.sync="page" :per-page="3" :pagination-position="'bottom'">
                  <b-table-column field="id" label="Report #" width="20" v-slot="props">
                    <b-tooltip :label="'#'+props.row.id">
                      {{ props.row.id.split('-')[0] }}
                    </b-tooltip>
                  </b-table-column>
                  <b-table-column field="priority" label="Priority" width="40" v-slot="props">
                    <span :class="{'tag': true, 'is-success': props.row.priority.toLowerCase() === 'low', 'is-danger': props.row.priority.toLowerCase() === 'high', 'is-warning': props.row.priority.toLowerCase() === 'medium'}">
                      {{ props.row.priority.toUpperCase() }}
                    </span>
                  </b-table-column>
                  <b-table-column field="Status" label="Status" width="40" v-slot="props">
                    <span :class="{'tag': true, 'is-success': props.row.status.toLowerCase() === 'open', 'is-danger': props.row.status.toLowerCase() === 'closed', 'is-warning': props.row.status.toLowerCase() === 'onhold', 'is-info': props.row.status.toLowerCase() === 'workingon', 'is-dark': props.row.status.toLowerCase() === 'ignored'}">
                      {{ props.row.status.toUpperCase() }}
                    </span>
                  </b-table-column>
                  <b-table-column field="lastUpdated" label="Last Updated" width="20" v-slot="props">
                    {{ new Date((props.row.lastUpdatedAt * 1000)).toLocaleString() }}
                  </b-table-column>
                  <template #empty>
                    <div class="has-text-centered">No records</div>
                  </template>
                </b-table>
              </div>
            </div>
            <div class="column is-centered has-shadow is-two-thirds list">
              <h1 v-if="!this.loaded" class="title is-2 noselect has-text-centered">Please wait, loading report page!</h1>
              <div v-else-if="this.bannedFromReports">
                <h1 class="title is-2 noselect has-text-centered">You are Banned!</h1>
                <h1 style="margin-bottom:0px" class="subtitle has-text-white is-5 noselect has-text-centered">You have have been banned from Grace Bot!</h1>
                <h1 style="margin-bottom:0px" class="subtitle has-text-white is-5 noselect has-text-centered">If you belive this is a mistake please join our discord.</h1>
                <img src='https://cdn.gracebot.net/grace/errorwarning.svg' style="height: 250px;padding-top: 10px;display: block;margin-left: auto;margin-right: auto;">
              </div>
              <h1 v-else-if="!selectedCategory" class="title is-2 noselect has-text-centered">Please select a report type!</h1>
              <div v-else-if="this.showFinished">
                <h1 class="title is-2 noselect has-text-centered">All done!</h1>
                <h1 style="margin-bottom:0px" class="subtitle has-text-white is-5 noselect has-text-centered">Your report has been submitted to the system for review!</h1>
                <h1 style="margin-bottom:0px" class="subtitle has-text-white is-5 noselect has-text-centered">Press the button below to submit a new one!</h1>
                <img src='https://cdn.gracebot.net/grace/finishedWellDone.svg' style="height: 250px;padding-top: 10px;display: block;margin-left: auto;margin-right: auto;">
              </div>
              <component :class="{'is-disabled': addingReport || !this.loaded}" id="reportPage" v-else-if="this.categorys && this.loaded" :is="this.categorys.find(g => g.type.toLowerCase() === this.selectedCategory.toLowerCase()).type.toLowerCase()" />
              <div style="padding-top: 20px;" v-if="this.selectedCategory && !this.showFinished && !this.bannedFromReports && this.loaded">
                <vue-recaptcha ref="recaptcha" @verify="onCaptchaVerified" @expired="onCaptchaExpired" theme="dark" size="invisible" :sitekey="this.$store.getters.google.recapPub"></vue-recaptcha>
                <div class="buttons" style="margin-bottom: unset !important;">
                  <b-button type="is-success" :loading="addingReport" :disabled="!this.allowSubmit || this.addingReport" @click='submit'>Submit</b-button>
                  <b-button type="is-info" :loading="addingReport" :disabled="this.addingReport" @click='cleanUp()'>Reset</b-button>
                </div>
              </div>
              <div style="padding-top: 20px;" v-else-if="this.showFinished">
                <div class="buttons" style="margin-bottom: unset !important;">
                  <b-button type="is-info" :loading="addingReport" @click='cleanUp()'>New Report</b-button>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </section>
  </div>
</template>

<script>
  import cdnimage from '@/components/reports/cdnimage.vue'
  import user from '@/components/reports/user.vue'
  import VueRecaptcha from 'vue-recaptcha'

  export default {
    name: 'Report',
    components: {
      cdnimage,
      user,
      VueRecaptcha
    },
    data () {
      return {
        categorys: [],
        showFinished: false,
        selectedCategory: null,
        addingReport: false,
        allowSubmit: false,
        bannedFromReports: false,
        userReports: [],
        loaded: false,
        retryUserReports: 0,
        page: 1,
        values: {}
      }
    },
    props: {
    },
    created () {
      this.getUserInfo()
    },
    methods: {
      async getUserInfo () {
        this.loaded = false
        if (!this.$store.getters.user) await this.$store.dispatch('sleep', 1000)
        this.$store.dispatch('getApi', { path: 'plugin/report/', params: {} }).then(async (result) => {
          this.loaded = true
          this.userReports = result.data.reports || []
          this.categorys = result.data.activeCategorys || []
          this.bannedFromReports = result.data.banned || false
          this.loaded = true
          if (this.$route.query.type) {
            if (!this.categorys.map(g => g.type).includes((this.$route.query.type || '').toLowerCase())) return
            this.selectedCategory = (this.$route.query.type || '').toLowerCase()
          }
        }).catch((error) => {
          console.log(error, 'error')
          this.retryamount += 1
          if (Number(error.response.status) === 404) {
            if (this.retryamount <= 3) return this.getProfile()
            return this.$router.push('/404?reason=User not found')
          }
          if (Number(error.response.status) === 500) {
            if (this.retryamount <= 3) return this.getProfile()
            return this.$router.push('/500?reason=' + error)
          }
          if (this.retryamount <= 3) return this.getProfile()
          return this.$router.push('/500?reason=unknown')
        })
      },
      submit () {
        this.$swal.fire({
          title: 'Submit Report?',
          html: "You won't be able to revert this report!<br>False reports may have unintended consequences.",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#48c78e',
          cancelButtonColor: '#f14668',
          confirmButtonText: 'Yes!'
        }).then((result) => {
          if (result.isConfirmed) this.$refs.recaptcha.execute()
        })
        // this.$refs.recaptcha.execute()
      },
      onCaptchaExpired: function () {
        this.addingReport = false
        this.$refs.recaptcha.reset()
      },
      onCaptchaVerified: function (recaptchaToken) {
        this.addingReport = true
        const snackbar = this.$buefy.snackbar.open({
          message: 'Sending Report, please wait...',
          type: 'is-warning',
          position: 'is-bottom-right',
          indefinite: true,
          actionText: null
        })
        this.$store.dispatch('postApi', { path: 'plugin/report/create', body: { type: this.selectedCategory, values: this.values, recaptcha: recaptchaToken }, headers: { recaptcha: recaptchaToken }, params: {} }).then(async (result) => {
          snackbar.close()
          this.$buefy.snackbar.open({
            message: 'Report Submitted!<br>Thanks for the report!',
            type: 'is-success',
            position: 'is-bottom-right'
          })
          await this.$store.dispatch('sleep', 2000)
          this.addingReport = false
          this.showFinished = true
          this.getUserInfo()
        }).catch(async (error) => {
          console.log(error, 'Error')
          snackbar.close()
          this.$buefy.snackbar.open({
            message: 'Oh no! Some error happened!',
            type: 'is-danger',
            position: 'is-bottom-right'
          })
          this.addingReport = false
          this.showFinished = false
          this.$refs.recaptcha.reset()
        })
      },
      async cleanUp () {
        this.addingReport = true
        const before = this.selectedCategory
        this.selectedCategory = null
        await this.$store.dispatch('sleep', 500)
        this.selectedCategory = before
        this.addingReport = false
        this.getUserInfo()
      }
    },
    watch: {
      selectedCategory () {
        this.allowSubmit = false
        this.values = {}
        this.showFinished = false
        console.log('Cleared values and allow submit in selectedCategory watch.')
      }
    },
    computed: {
    }
  }
</script>

<style lang="scss" scoped>
  .has-shadow {
    box-shadow: 0 0.1rem 1.1rem rgba(0,0,0,.3)!important
  }

  .list {
    background: var(--main-color-bg-3);
    border-radius: 10px;
    min-height: 64px;
    margin-top: 10px;
    height: fit-content;
  }
  @media screen and (min-width: 768px) {
    .list {
      margin-left: 40px;
      margin-top: 0px !important;
    }
  }
  .options {
    background: var(--main-color-bg-3) !important;
    border-radius: 10px !important;
    min-height: 200px !important;
    height: fit-content !important;
    padding: 10px;
  }
  .options:not(:first-child) {
    margin-top: 30px;
  }

</style>

<style>
  .menu-list li ul {
    border-left: 1px solid var(--main-color-bg-3) !important;
  }
  .menu-label {
    color: lightgrey;
  }
  .menu-list a {
    color: white !important;
    border-radius: 10px !important;
  }
  .menu-list a.is-active:hover {
    background-color: var(--main-color-bg-2) !important;
    border-radius: 10px !important;
  }
  .menu-list a:hover {
    background-color: var(--main-color-bg-1) !important;
    border-radius: 10px !important;

    color: white !important;
  }
  .menu-list a.is-active {
    background-color: #7957d5 !important;
    border-radius: 10px !important;
  }
  .menu-list {
    border-radius: 10px !important;
    box-shadow: 0 0.1rem 0.3rem rgba(0,0,0,.3)!important;
    background-color: var(--main-color-bg-2) !important;
  }
</style>
