<template>
  <div>
    <h1 class="title is-3 noselect has-text-left">User Report</h1>
    <h2 class="subtitle has-text-grey is-6">Report a user for abusing Grace!</h2>
    <b-field label="Reporting User*">
      <div v-if="this.$store.getters.user" style="align-items: left; justify-content: left; display: flex;">
        <img :src="'https://cdn.discordapp.com/avatars/' + this.$store.getters.user.id + '/' + this.$store.getters.user.avatar + '.webp?size=128'" onerror="this.src='https://cdn.discordapp.com/embed/avatars/1.png?size=128';" class="icon-img has-shadow" style="max-height: 36px; margin-right: 5px; border-radius: 100px; border-color: var(--main-color-bg-3); border-width: 1px; border-radius: 100px; border-style: solid; background-color: var(--main-color-bg) !important;">
        <p style="line-height:35px">{{ this.$store.getters.user.username }}#{{ this.$store.getters.user.discriminator }}</p>
      </div>
      <b-button tag="router-link" :to="{path: '/login'}" v-else type="is-primary">Login</b-button>
    </b-field>
    <b-field grouped class="hovermessagefeild" label="Reported User*">
      <div class="control" style="align-items: left; justify-content: left; display: flex;">
        <img :src="this.user ? 'https://cdn.discordapp.com/avatars/' + this.user.id  + '/' + this.user.avatar + '.webp?size=128' : 'https://cdn.discordapp.com/embed/avatars/1.png?size=128'" onerror="this.src='https://cdn.discordapp.com/embed/avatars/1.png?size=128';" class="icon-img has-shadow" style="max-height: 36px; margin-right: 5px; border-radius: 100px; border-color: var(--main-color-bg-3); border-width: 1px; border-radius: 100px; border-style: solid; background-color: var(--main-color-bg) !important;">
        <p style="line-height:35px" v-if="this.user">{{ this.user.username }}#{{ this.user.discriminator }}</p>
        <p style="line-height:35px" v-else>Username#0000</p>
        <b-loading :is-full-page="false" v-model="isLoadingUser" :can-cancel="false">
          <b-icon pack="fas" icon="sync-alt" size="is-small" custom-class="fa-spin"></b-icon>
        </b-loading>
      </div>
      <b-input required expanded @keypress.native="(e) => {if (isNaN(parseInt(e.key, 10)) && e.key !== 'Backspace' && e.key !== 'Enter') { e.preventDefault(); }}" custom-class="input-default" maxlength="20" :value="searchUser" :has-counter="false" placeholder="Enter user ID" @input="onSearchUser" :icon-right="searchUser ? 'close-circle' : null" icon-right-clickable @icon-right-click="searchUser = null; user = null;"></b-input>
    </b-field>
    <a target="_blank" href="https://support.discord.com/hc/en-us/articles/206346498-Where-can-I-find-my-User-Server-Message-ID-?ref=gracebot" class="is-6" style="margin-bottom: 5px;">
      Click Here to figure out how to get a user id!
    </a>
    <b-field label="Reason *">
      <b-input :icon-right="reason ? 'close-circle' : null" icon-right-clickable @icon-right-click="reason = null;" required maxlength="500" rows="5" placeholder="Enter a short message on the reason why your reporting this image." v-model="reason" :has-counter="true" custom-class="input-default" type="textarea"></b-input>
    </b-field>
  </div>
</template>

<script>
  import { debounce } from 'underscore'

  export default {
    name: 'user',
    components: {
    },
    data () {
      return {
        SelectPlacement: 'bottom',
        user: null,
        reason: null,
        isLoadingUser: false,
        searchUser: null,
        requiredValues: ['userid', 'reason'],
        optionalValues: []
      }
    },
    created () {
      for (let i = 0; i < [...this.requiredValues, ...this.optionalValues].length; i++) {
        const element = [...this.requiredValues, ...this.optionalValues][i]
        console.log(`Now Watching ${element} in user`)
        this.$parent.values[element] = (this[element] === '' || this[element] === '' ? null : this[element]) || null
        this.$watch(element, function () {
          console.log(this[element] || null, element)
          this.$parent.values[element] = (this[element] === '' || this[element] === '' ? null : this[element]) || null
          this.updateValues()
          return true
        })
      }
      if (this.$route.query.reportId) this.onSearchUser(this.$route.query.reportId)
    },
    watch: {
    },
    computed: {
      userid: function () {
        if (this.user?.id) return this.user.id
        return null
      }
    },
    methods: {
      updateValues () {
        let allowSubmit = true
        for (let i = 0; i < this.requiredValues.length; i++) {
          const element = this.requiredValues[i]
          if (this[element] === undefined || this[element] === null) {
            allowSubmit = false
            break
          }
        }
        this.$parent.allowSubmit = allowSubmit
        return allowSubmit
      },
      onSearchUser (search) {
        if (!search || search.length <= 0 || this.searchUser === search.trim()) return
        this.searchUser = ((search.trim() || '').replaceAll(/\D/g, '') || null)
        console.log(this.searchUser, search.trim())
        this.searchUserAPI(this.searchUser, this)
      },
      searchUserAPI: debounce((search, vm) => {
        vm.isLoadingUser = true
        vm.user = null
        if (vm.searchUser.length >= 17) {
          vm.$store.dispatch('getApi', { path: `plugin/profile/${search}` }).then(async (result) => {
            // vm.imageList = result.data.results
            if (result.data.user.id === vm.$store.getters.user.id) {
              vm.user = null
              vm.searchUser = null
              vm.isLoadingUser = null
            } else {
              vm.user = result.data.user
              vm.isLoadingUser = false
            }
          }).catch(async () => {
            // vm.imageList = []
            vm.isLoadingUser = false
          })
        } else vm.isLoadingUser = false
      }, 350)
    }
  }
</script>

<style>
  .vs--disabled .vs__dropdown-toggle, .vs--disabled .vs__clear, .vs--disabled .vs__search, .vs--disabled .vs__selected, .vs--disabled .vs__open-indicator {
    background: var(--main-color-bg-2);
    cursor: not-allowed;
    color: #7a7a7a !important;
  }
  .vs__dropdown-toggle {
    background: var(--main-color-bg-2);
    color: white !important;
    border-color: var(--main-color-bg-3) !important;
    min-height: 2.25em !important;
    height: 100% !important;
    border: 1px solid transparent;
    border-radius: 4px;
    box-shadow: 0 0.1rem 1.1rem rgba(0,0,0,.3) !important;
  }
  .vs__dropdown-menu {
    background: var(--main-color-bg-1);
    color: white;
    border-color: var(--main-color-bg-3) !important;
    border-top-style: unset !important;
    border-radius: 4px 4px 4px 4px !important;
  }
  .vs__open-indicator {
    fill: #6045aa !important;
  }
  .vs__open-indicator:hover {
    fill: #7957d5 !important;
  }
  .vs__clear {
    fill: darkred !important;
  }
  .vs__clear:hover {
    fill: red !important;
  }
  .vs--single .vs__selected {
    color: white;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
    overflow: hidden !important;
  }
  .vs__dropdown-option--selected {
    background-color: var(--main-color-bg-2);
  }
  .vs__dropdown-option--highlight {
    background: var(--main-color-bg-3) !important;
  }
  .vs__actions {
    cursor: pointer;
  }
  .vs__search, .vs__search:focus {
    color: white;
  }
  .vs__selected {
    background-color: var(--main-color-bg-1);
    color: white;
  }
  .vs__deselect {
    fill: darkred;
  }
  .vs__deselect:hover {
    fill: red;
  }
</style>
